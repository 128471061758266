import { initialStateUser } from '@constants/generalInitialStates';

export const organization = () => {
  const organizationId = localStorage.getItem('organization') || '';
  return `organizations/${organizationId}`;
};

export const realm = () => {
  const userStorage =
    localStorage.getItem('user') || JSON.stringify(initialStateUser);
  const getRealmId = JSON.parse(userStorage).realm.id;
  return `realms/${getRealmId}`;
};

export const user = () => {
  const getCurrentUser =
    localStorage.getItem('user') || JSON.stringify(initialStateUser);
  const currentUser = JSON.parse(getCurrentUser).id;

  return `users/${currentUser}`;
};

export const endpoint = {
  preSignIn: 'auth/pre-sign-in',
  signIn: 'auth/sign-in',
  getMfa: 'current-user/mfa',
  newPassword: 'auth/confirm-password-expired',
  forgotPassword: 'auth/forgot-password',
  confirmForgotPassword: 'auth/confirm-forgot-password',
  mfa: 'auth/confirm-mfa',
  refreshToken: 'auth/refresh-token',
  currentUser: 'current-user',
  users: 'users',
  roles: 'roles',
  statements: 'statements',
  company: 'company',
  wallets: 'wallets',
  customFields: 'custom-fields',
  organizations: 'organizations',
  billings: 'billing',
  customers: 'customer',
  batchPayment: 'batch-payment',
  transactions: 'transactions',
  invoices: 'invoices',
  memberGetMember: 'member-get-member',
  billingDeals: 'billing-deals',
  billingInvoices: 'billing-invoices',
  deals: 'deals',
  schedules: 'schedules',
  contracts: 'contracts',
};
